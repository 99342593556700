
import { computed, reactive, toRefs } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

import {
  IonPage,
  IonContent,
  IonSearchbar,
  IonItem,
  IonTitle,
  IonLabel,
  IonButtons,
  IonList,
  IonHeader,
  IonToolbar
} from '@ionic/vue';
import { chevronBackOutline } from 'ionicons/icons';
export default {
  name: 'Archive',
  components: {
    IonPage,
    IonContent,
    IonSearchbar,
    IonItem,
    IonTitle,
    IonLabel,
    IonButtons,
    IonList,
    IonHeader,
    IonToolbar
  },
  setup(): any {
    const store = useStore();
    const state = reactive({ searchString: '' });
    const route = useRoute();

    const type = computed(() => {
      switch (route.params.type) {
        case 'problems':
          return 'content/wpSleepProblems';
        case 'solutions':
          return 'content/wpSleepSolutions';
        default:
          return 'content/wpSleepResources';
      }
    });

    const title = computed(() => {
      switch (route.params.type) {
        case 'problems':
          return 'Problems';
        case 'solutions':
          return 'Solutions';
        default:
          return 'Resources';
      }
    });

    const introCopy = computed(() => {
      switch (route.params.type) {
        case 'problems':
          return 'Below is a list of some common sleep problems. Scroll through or use the search feature to find your sleep problem and view related information.';
        case 'solutions':
          return 'Below are solutions for sleep problems, find out more by tapping on the items below or use the search feature.';
        default:
          return 'You can find further information and resources linked in the list below, or use the search feature.';
      }
    });

    const slug = computed(() => {
      return route.params.type;
    });

    return {
      ...toRefs(state),
      title,
      slug,
      introCopy,
      chevronBackOutline,
      filteredPages: computed(() => {
        if (!state.searchString) {
          return store.getters[type.value];
        }

        return store.getters[type.value].filter(page => {
          const haystack = `${page.title.rendered} ${page.content.rendered}`.toLowerCase();
          const needle = state.searchString.toLowerCase();
          return haystack.includes(needle);
        });
      })
    };
  }
};
